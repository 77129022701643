/* This File is for {purpose} */

<template>
  <v-layout wrap justify-center pa-8>
    <v-flex xs12 lg3>
      <v-form ref="form" lazy-validation>
        <v-layout wrap justify-center>
          <v-flex xs12 text-center>
            <img src="@/assets/logo.svg" height="50px" contain alt="logo" />
          </v-flex>
          <v-flex xs12 py-12></v-flex>
          <v-flex xs12>
            <AppNavButton :route="{ name: 'login' }" outlined color="#C0CBE0">
              <template #content>
                <v-icon color="#000000" size="20px">mdi-arrow-left</v-icon>
              </template>
            </AppNavButton>
          </v-flex>
          <V-flex xs12 pt-8>
            <span class="title-main">
              {{ $t("pages.forgot_password.title") }}
            </span>
            <br />
            <p class="subtitle-2">
              {{ $t("pages.forgot_password.sub_heading") }}
            </p>
          </V-flex>
          <v-flex xs12 pt-8>
            <p class="input-label">{{ $t("form.email") }}</p>
            <v-text-field
              dense
              outlined
              v-model="email"
              :rules="[RULES.REQUIRED]"
            />
          </v-flex>
          <v-flex xs12 pt-12> </v-flex>
          <v-flex xs12 pt-12>
            <AppButton
              block
              custom-class="py-5"
              label="pages.forgot_password.send_email"
              @submit="sentLink"
            />
          </v-flex>
        </v-layout>
      </v-form>
    </v-flex>
    <SuccessConfirmation
      v-model="showSuccess"
      title="pages.forgot_password.email_sent"
      description="pages.forgot_password.email_sent_description"
      @submit="success"
    />
  </v-layout>
</template>

<script>
import { RULES } from "@/libs/helper/components/rules";

export default {
  name: "ForgotPassword",
  data: () => ({
    RULES,
    showSuccess: false,
    email: null,
  }),
  methods: {
    success() {
      this.$router.push({ name: "login" });
    },
    toggleSuccess() {
      this.showSuccess = !this.showSuccess;
    },
    sentLink() {
      console.log("email sent");
      if (!this.$refs.form.validate()) return;
      const data = {
        email: this.email,
      };
      this.$store
        .dispatch("app/forgotPassword", data)
        .then((res) => {
          console.log(res);
          this.$refs.form.reset();
          this.toggleSuccess();
        })
        .catch((err) => {
          console.log(err);
          this.message = err.response.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
      // this.$router.push({ name: "reset-password" });
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}
</style>
