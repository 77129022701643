var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { wrap: "", "justify-center": "", "pa-8": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", lg3: "" } },
        [
          _c(
            "v-form",
            { ref: "form", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "justify-center": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "text-center": "" } }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/logo.svg"),
                        height: "50px",
                        contain: "",
                        alt: "logo",
                      },
                    }),
                  ]),
                  _c("v-flex", { attrs: { xs12: "", "py-12": "" } }),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("AppNavButton", {
                        attrs: {
                          route: { name: "login" },
                          outlined: "",
                          color: "#C0CBE0",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "#000000", size: "20px" } },
                                  [_vm._v("mdi-arrow-left")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("V-flex", { attrs: { xs12: "", "pt-8": "" } }, [
                    _c("span", { staticClass: "title-main" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("pages.forgot_password.title")) +
                          " "
                      ),
                    ]),
                    _c("br"),
                    _c("p", { staticClass: "subtitle-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("pages.forgot_password.sub_heading")) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "pt-8": "" } },
                    [
                      _c("p", { staticClass: "input-label" }, [
                        _vm._v(_vm._s(_vm.$t("form.email"))),
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          rules: [_vm.RULES.REQUIRED],
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs12: "", "pt-12": "" } }),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "pt-12": "" } },
                    [
                      _c("AppButton", {
                        attrs: {
                          block: "",
                          "custom-class": "py-5",
                          label: "pages.forgot_password.send_email",
                        },
                        on: { submit: _vm.sentLink },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SuccessConfirmation", {
        attrs: {
          title: "pages.forgot_password.email_sent",
          description: "pages.forgot_password.email_sent_description",
        },
        on: { submit: _vm.success },
        model: {
          value: _vm.showSuccess,
          callback: function ($$v) {
            _vm.showSuccess = $$v
          },
          expression: "showSuccess",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }